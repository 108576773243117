export default {

  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },

  render(h) {

    let steps = this.$scopedSlots.default({ currentStep: this.currentStep });
    this.maxSteps = steps.length;
    return h('div', { class: 'col-xs-12 vertical-stepper' }, steps);

  }

};