import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Money from 'js-money';
import Velocity from 'velocity-animate';
import VeeValidate from 'vee-validate';


// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main.js';

// router setup
import routes from './routes/routes.js';

// PrismJS
import VuePrism from 'vue-prism';
import 'prismjs/themes/prism.css';

// Vue Notify
import VueNotify from 'vue-notifyjs';
import 'vue-notifyjs/themes/default.css';

// Vue QRCode
import VueQRCodeComponent from 'vue-qrcode-component'
import VerticalStepper from './components/UIComponents/Steppers/VerticalStepper';
import ToggleButton from 'vue-js-toggle-button';
import VueCookies from 'vue-cookies'

// Vee Validate configs

// eslint-disable-next-line no-unused-vars
import { Validator } from 'vee-validate';

const config = {
  locale: 'it'
};

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(VueNotify);
Vue.use(VuePrism);
Vue.use(VeeValidate, config);
Vue.use(ToggleButton);
Vue.use(VueCookies, { expires: '30d'});
Vue.component('v-stepper', VerticalStepper);
Vue.component('qr-code', VueQRCodeComponent)


window.Money = Money;
window.Velocity = Velocity;


// configure router
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'nav-item active',
  routes: routes
});

/* eslint-disable no-new */
new Vue({
  
  render: (h) => h(App),
  router,
}).$mount('#app');
