

const routes = [
  {
    path: '/',
    component: ()=> import('../components/Dashboard/Layout/DashboardLayout.vue'),
    redirect: '/ecommerce',
    children: [
      {
        path: '/ecommerce',
        name: 'Tinaba Pay',
        component: ()=> import('../components/Dashboard/Views/EcommerceMode.vue')
      },
      {
        path: '/cash-register',
        name: 'Tinaba Pay',
        component: ()=> import('../components/Dashboard/Views/CashRegisterMode.vue')
      },
      {
        path: '/preauth',
        name: 'Tinaba Pay & Check',
        component: () => import('../components/Dashboard/Views/PreauthMode.vue')
      },
      {
        path: '/media',
        name: 'Tinaba Pay for Media',
        component: () => import('../components/Dashboard/Views/MediaMode.vue')
      },
      {
        path: '/verify',
        name: 'Verifica Signature',
        component: () => import('../components/Dashboard/Views/SignatureVerifyer.vue')
      }
    ]
  },
  { path: '*', component: ()=> import('../components/GeneralViews/NotFoundPage.vue') }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
